import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s03_1_1_1 : false,
      s03_1_1_2 : false,
      s03_1_1_3 : false,
      s03_1_1_4 : false,
      s03_1_1_5 : false,
      s03_1_1_6 : false,
      s03_1_1_7 : false,
    }

    this.searchInit = {
      filter1Init: 2,
      filter2Init: 0,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>サイズ別ジェット旅客機運航機数および需要予測</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_1"}>
                <img src={'../../images/chart/s03-1-1-1.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_1}
                close={this.closeModal}
                name={"s03_1_1_1"}
                src={'/images/chart/s03-1-1-1.png'}
                alt="サイズ別ジェット旅客機運航機数および需要予測"
                width={923}
                height={608} />
              <div className={chartStyles.chartNote}>
              <p className={chartStyles.chartSource}>出所）<a href="http://www.jadc.jp/files/topics/174_ext_01_0.pdf" target="_blank" rel="noreferrer noopener">（一財）日本航空機開発協会 "民間航空機に関する市場予測 2022-2041"</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}></p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>サイズ別ジェット貨物機の運航機数および需要予測</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_2"}>
                <img src={'../../images/chart/s03-1-1-2.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_2}
                close={this.closeModal}
                name={"s03_1_1_2"}
                src={'/images/chart/s03-1-1-2.png'}
                alt="サイズ別ジェット貨物機の運航機数および需要予測"
                width={1028}
                height={637} />
              <div className={chartStyles.chartNote}>
              <p className={chartStyles.chartSource}>出所）<a href="http://www.jadc.jp/files/topics/174_ext_01_0.pdf" target="_blank" rel="noreferrer noopener">（一財）日本航空機開発協会 "民間航空機に関する市場予測 2022-2041"</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}></p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
              <h2>ATAG Waypoint2050におけるT5シナリオにおける燃料種別シェア</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_3"}>
                <img src={'../../images/chart/s03-1-1-3.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_3}
                close={this.closeModal}
                name={"s03_1_1_3"}
                src={'/images/chart/s03-1-1-3.png'}
                alt="ATAG Waypoint2050におけるT5シナリオにおける燃料種別シェア"
                width={1101}
                height={470} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG "WAYPOINT2050"</a>(2021年9月)</p>
                <p className={chartStyles.chartExplain}>ATAG Waypoint2050（T5シナリオ）における航空部門のエネルギー種別の需要を原油換算トンで表記したもの。<br />
                <br />
                T5シナリオは、WAYPOINT2050(ATAG,2021)において最も野心的に航空技術を導入するシナリオ</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>Cranfield大学による燃料種別の脱炭素シナリオにおける航空機燃料種シェア</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_4"}>
                <img src={'../../images/chart/s03-1-1-4.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_4}
                close={this.closeModal}
                name={"s03_1_1_4"}
                src={'/images/chart/s03-1-1-4.png'}
                alt="Cranfield大学による燃料種別の脱炭素シナリオにおける航空機燃料種シェア"
                width={998}
                height={472} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.fzt.haw-hamburg.de/pers/Scholz/dglr/hh/text_2021_05_06_Hydrogen.pdf" target="_blank" rel="noreferrer noopener">Cranfield University "Enabling Cryogenic Hydrogen-Based CO2-Free Air Transport"</a>（2021年5月）</p>
                <p className={chartStyles.chartExplain}>Cranfield大学が想定した将来的な航空部門の燃料シェアを図示したもの</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ATAG Waypoint2050における飛行距離別の電動航空機・ハイブリッド航空機・水素航空機の導入想定</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_5"}>
                <img src={'../../images/chart/s03-1-1-5.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_5}
                close={this.closeModal}
                name={"s03_1_1_5"}
                src={'/images/chart/s03-1-1-5.png'}
                alt="ATAG Waypoint2050における飛行距離別の電動航空機・ハイブリッド航空機・水素航空機の導入想定"
                width={937}
                height={608} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG "WAYPOINT2050"</a>(2021年9月)</p>
                <p className={chartStyles.chartExplain}>本チャートでは航空機のサイズ別に、将来導入が想定されるエネルギー種を整理している</p>
              </div>
            </div>
            
            <div className={dashboardStyles.chartAreaItem}></div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>LTAGにおけるナローボディ機の次世代航空機市場シェア</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_6"}>
                <img src={'../../images/chart/s03-1-1-6.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_6}
                close={this.closeModal}
                name={"s03_1_1_6"}
                src={'/images/chart/s03-1-1-6.png'}
                alt="LTAGにおけるナローボディ機の次世代航空機市場シェア"
                width={1200}
                height={602} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.icao.int/environmental-protection/LTAG/Documents/ICAO_LTAG_Report_AppendixM3.pdf" target="_blank" rel="noreferrer noopener">ICAO "Report on the feasibility of a long-term aspirational goal(LTAG) for international cicil aviation CO2 emission reductions Appendix M3 Technology Sub Group Report"</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>本チャートは、LTAGのIS1,2,3各シナリオに想定されている燃料種別の上市機体数とシェアを描いたもの<br />
                <br />
                ATW-T1は従来型機材の形態を維持し燃費改善技術を導入した機材、ACA-T2は新たな構造コンセプト等の革新的技術を導入した機材（燃料はドロップイン燃料）、ACA-T3は水素航空機</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>LTAGにおけるワイドボディ機の次世代航空機市場シェア</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_1_7"}>
                <img src={'../../images/chart/s03-1-1-7.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_1_7}
                close={this.closeModal}
                name={"s03_1_1_7"}
                src={'/images/chart/s03-1-1-7.png'}
                alt="LTAGにおけるワイドボディ機の次世代航空機市場シェア"
                width={1094}
                height={547} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.icao.int/environmental-protection/LTAG/Documents/ICAO_LTAG_Report_AppendixM3.pdf" target="_blank" rel="noreferrer noopener">ICAO "Report on the feasibility of a long-term aspirational goal(LTAG) for international cicil aviation CO2 emission reductions Appendix M3 Technology Sub Group Report"</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>本チャートは、LTAGのIS1,2,3各シナリオに想定されている燃料種別の上市機体数とシェアを描いたもの<br />
                <br />
                ATW-T1は従来型機材の形態を維持し燃費改善技術を導入した機材、ACA-T2は新たな構造コンセプト等の革新的技術を導入した機材（燃料はドロップイン燃料）、ACA-T3は水素航空機</p>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`